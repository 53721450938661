$(function () {
  $('a[href^="#"]').click(function(e) {
    e.preventDefault();
    var target = $(this.hash);
    if (target.length) {
      $('html,body').animate({ scrollTop: target.offset().top - 80 }, 800, 'swing');
      window.history.pushState(null, null, this.hash);
    }
  });

  $('#close').click(function() {
    $('#navigation-2').slideUp(240);
  });

  $('#navigation-2-trigger, #navigation-2').hover(
    function() {
      $('#navigation-2').stop().slideDown(240);
    },
    function() {
      $('#navigation-2').stop().slideUp(240);
    }
  );

  $(window).scroll(function() {
    var now = $(window).scrollTop();
    $('#page-top, #pageSsba').fadeToggle(now > 480, 240);
  });

  $('#page-top a').hover(
    function() {
      $(this).stop().animate({ opacity: 0.64 }, 240, 'swing');
    },
    function() {
      $(this).stop().animate({ opacity: 1 }, 240, 'swing');
    }
  ).click(function() {
    $('html, body').animate({ scrollTop: 0 }, 800, 'swing');
  });

  // .home__modal click event to add .is-close
  $('.home__modal-close').click(function() {
    $('.home__modal').addClass('is-close');
  });
});
